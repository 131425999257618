import './bottomPartGraduate.css';
import ButtonSimple from '../../../components/form/inputs/buttonSimple/buttonSimple.js'; 
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { PaginatedList } from 'react-paginated-list';

export default function BottomPartGraduate(props) {

    return (
        <div className="BottomPartGraduate">
            <div className='career-part'>
                <div className='career-part-inner'>
                    <h3>Career potential</h3>
                    <p>{props.careerP}</p>
                    <div className='career-list-wrap'>
                        <Col className='career-list d-flex justify-content-start'>
                            <ul>
                                {props.careerList.slice(0, (props.careerList.length / 2)).map((data, idx) => (
                                    <li key={idx}>
                                        {data}
                                    </li>
                                ))}
                            </ul>
                        </Col>
                        <Col className='career-list d-flex justify-content-end'>
                            <ul>
                                {props.careerList.slice((props.careerList.length / 2), (props.careerList.length)).map((data, idx) => (
                                    <li key={idx}>
                                        {data}
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    </div>
                    <ButtonSimple text='Register' link='/register' />
                </div>
            </div>
            <div className="cohort-part">
                <div className='cohort-part-inner'>
                    <h3>Current cohort</h3>
                    {/* <Row>
                        <Col xs={12} md={12} lg={12}>
                            <ul className='list-cohort'>
                                <PaginatedList
                                    list={props.cohortList}
                                    itemsPerPage={8}
                                    nextText='Next'
                                    prevText='Prev.'
                                    loopAround={false}
                                    displayNumbers={true} 
                                    displayRange={1}
                                    isLoading={false}
                                    renderList={(list) => (
                                        <>
                                            {list.map((data, idx) => {
                                                return (
                                                    <li key={idx} className='item-list d-flex'>
                                                        <div>
                                                            {data}
                                                        </div>
                                                        <div>
                                                            {data}
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </>
                                    )}
                                />
                            </ul>
                        </Col>
                    </Row> */}
                </div>
            </div>
        </div>
    );
}

// props :
// careerP - careerList1 - careerList2 - cohortList