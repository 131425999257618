import './buttonSimple.css'; 
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

export default function ButtonSimple(props) {
  return (
    <div className="ButtonSimple">
      {(props.link.length > 0) ? <Link to={props.link}>{props.text}</Link> : <Button onClick={props.click}>{props.text}</Button>}
    </div>
  );
}

// props :
// text - link - click