import './App.css';

import { Route, Routes, Navigate } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";

// Views
import Intro from './views/intro/intro';
import Home from './views/home/home';
import Gateway from './views/gateway/gateway';
import About from './views/about/about';
import Blogs from './views/blogs/blogs';
import Blog from './views/innerBlog/innerBlog';
import Contact from './views/contact/contact';
import Graduates from './views/graduates/graduates';
import Graduate from './views/graduate/graduate';
import Partners from './views/partners/partners';
import Team from './views/team/team';
import Register from './views/register/register';
import Error404 from './views/errors/error404/error404';

export default function App() {

  // const routes = [
  //   { path: "/", component: Intro },
  //   { path: "/home", component: Home },
  //   { path: "/gateway/:post_id", component: Gateway },
  //   { path: "/about", component: About },
  //   { path: "/blogs", component: Blogs },
  //   { path: "/blog/:blog_id", component: Blog },
  //   { path: "/contact", component: Contact },
  //   { path: "/graduates", component: Graduates },
  //   { path: "/graduate/:graduate_id", component: Graduate },
  //   { path: "/partners", component: Partners },
  //   { path: "/team", component: Team },
  //   { path: "/register", component: Register },
  //   { path: "*", component: Error404 }
  // ];

  return (
    <div className='App'>
      {/* <Routes onUpdate={() => window.scrollTo(0, 0)}> */}
      <AnimatePresence>
        <Routes>
          {/* {routes.map((data, idx) => {
            <Route exact strict key={idx} path={data.path} element={<Navigate to={data.component} replace />} />
          })} */}
          <Route exact path="/" element={<Intro />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/gateway/:post_id" element={<Gateway />} />
          <Route exact  path="/about" element={<About />} />
          <Route exact  path="/blogs" element={<Blogs />} />
          <Route exact  path="/blog/:blog_id" element={<Blog />} />
          <Route exact  path="/contact" element={<Contact />} />
          <Route exact  path="/graduates" element={<Graduates />} />
          <Route exact  path="/graduate/:graduate_id" element={<Graduate />} />
          <Route exact  path="/partners" element={<Partners />} />
          <Route exact  path="/team" element={<Team />} />
          <Route exact  path="/register" element={<Register />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}
