import './header.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../../assets/images/logo.png';
import { Sling as Hamburger } from 'hamburger-react';
import { FaLinkedinIn, FaTwitter, FaFacebookSquare, FaYoutube } from "react-icons/fa"; 
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal'; 
import { Link, NavLink } from 'react-router-dom';

export default function Header() {

  const [isOpen, setOpen] = useState(false);
  const handleCloseIcon = () => setShow(false);

  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const handleClose = () => setShow(false);
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  return (
    <div className="Header">
      <Navbar>
        <Container>
          <Navbar.Collapse>
            <Navbar.Brand>
              <Link to="/home" replace>
                <img src={logo} width="" height="" alt="Logo" />
              </Link>
              <Hamburger rounded size={26} toggled={isOpen} toggle={setOpen} direction="left" duration={0.6} color="#6B6B6B" onToggle={toggled => {
                if (toggled) {
                  handleShow();
                } else {
                  handleClose();
                }
              }} />
            </Navbar.Brand>
            <Navbar.Text >
              <div className='title'>
                <h6>Empowering Transitions to Sustainable Livelihoods </h6>
                <p>Building local possibilities through our Open Collaborative design Spaces</p>
              </div>
              {/* <Hamburger rounded size={26} toggled={isOpen} toggle={setOpen} direction="left" duration={0.6} color="#6B6B6B" onToggle={toggled => {
                if (toggled) {
                  handleShow();
                } else {
                  handleClose();
                }
              }} /> */}
            </Navbar.Text>
            <Navbar.Text className='right-links'>
              <a target="_blank" href='https://rootdiamonds.com'>Shop</a>
              {/* <Link to=""  target="_blank" onClick={[handleClose, handleCloseIcon]} /Link> */}
              <Link to='/register' onClick={[handleClose, handleCloseIcon]} replace>Register</Link>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
        <Modal show={show} onHide={handleClose} fullscreen={fullscreen} className="overlay-menu">
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <ul className='d-flex flex-column justify-content-center'>
            <li><NavLink to="/home" onClick={[handleClose, handleCloseIcon]} replace>Home</NavLink></li>
            <li><NavLink to="/about" onClick={[handleClose, handleCloseIcon]} replace>About us</NavLink></li>
            <li><NavLink to="/team" onClick={[handleClose, handleCloseIcon]} replace>Team</NavLink></li>
            {/* <li><NavLink to="/partners" onClick={[handleClose, handleCloseIcon]} replace>Partners</NavLink></li> */}
            <li><NavLink to="/graduates" onClick={[handleClose, handleCloseIcon]} replace>Graduates</NavLink></li>
            <li><NavLink to="/contact" onClick={[handleClose, handleCloseIcon]} replace>Contact</NavLink></li>
            {/* <li><NavLink to="/blogs" onClick={[handleClose, handleCloseIcon]} replace>Blogs</NavLink></li> */}
            </ul>
          </Modal.Body>
          <Modal.Footer> 
          <a target="_blank" href='https://rootdiamonds.com'>Shop</a>
            {/* <Link to='/register' onClick={[handleClose, handleCloseIcon]} replace>Shop</Link>   */}
            <Link to='/register' onClick={[handleClose, handleCloseIcon]} replace>Register</Link>
            <p>&copy; {(new Date().getFullYear())} Root Studios. All Right Reserved</p>
            {/* <div className='social-media'>
              <a href='https://www.linkedin.com' target='_blanck'><FaLinkedinIn size={18} /></a>
              <a href='https://www.twitter.com' target='_blanck'><FaTwitter size={18} /></a>
              <a href='https://www.facebook.com' target='_blanck'><FaFacebookSquare size={18} /></a>
              <a href='https://www.youtube.com' target='_blanck'><FaYoutube size={18} /></a>
            </div> */}
          </Modal.Footer>
        </Modal>
      </Navbar>
    </div>
  );
}