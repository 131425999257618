import './videoPlayerGateway.css'; 
import { useRef ,useState } from "react"; 
import fullscreen from '../../../assets/images/fullscreen.png';
import videoPoster from '../../../assets/images/intro_page/video-poster.jpg';

export default function VideoPlayerGateway(props) {
 
    const videoRef = useRef(null); 

    const videoHandler = (control) => {
        if (control === "play") {
            videoRef.current.play();
            setPlaying(true);
            var vid = document.getElementById("video1");
            setVideoTime(vid.duration);
            document.getElementById("video-title").style.setProperty('opacity','0');
            document.getElementById("video-subtitle").style.setProperty('opacity','0');
            document.getElementById("video-time").style.setProperty('opacity','1');
        } else if (control === "pause") {
            videoRef.current.pause();
            setPlaying(false);
            document.getElementById("video-title").style.setProperty('opacity','1');
            document.getElementById("video-subtitle").style.setProperty('opacity','1');
            document.getElementById("video-time").style.setProperty('opacity','0');
        }
    };
    const [playing, setPlaying] = useState(false);    

  
    window.setInterval(function () {
        setCurrentTime(videoRef.current?.currentTime);
    }, 1000); 
    const [currentTime, setCurrentTime] = useState(0);

    const [videoTime, setVideoTime] = useState(0);

    const [progress, setProgress] = useState(0);
    // setProgress((videoRef.current?.currentTime / videoTime) * 100);
        window.setInterval(function () {
        setCurrentTime(videoRef.current?.currentTime);
        setProgress((videoRef.current?.currentTime / videoTime) * 100);
    }, 1000);
   
    document.documentElement.style.setProperty('--percent', progress);
    
    
    const fullscreenOn = () => {
        document.getElementById("videoPlayercs").classList.add('fullScreen');
    }
    const fullscreenOff = () => {
        document.getElementById("videoPlayercs").classList.remove('fullScreen');
    }


        
    return (
        <div className="videoOuter"> 
            <div className='videoPlayer' id='videoPlayercs'>
                <video id="video1" playsInline="1"  poster={videoPoster} preload="auto"
                    ref={videoRef}
                    className="video"
                    src={props.video} 
                > 
                </video>
                <div className='title-outer'>
                    <h1 className="title" id='video-title'>{props.title}</h1>
                </div>
                <div className='fullscreen-mode' onClick={fullscreenOn}>
                    <img src={fullscreen}/>
                </div>
                <div className='fullscreen-mode-off' onClick={fullscreenOff}>
                    <img src={fullscreen}/>
                </div>
                <div className='video-controls'> 
                    <svg>
                        <circle cx="50" cy="50" r="46"></circle>
                        <circle cx="50" cy="50" r="46"  ></circle>
                    </svg> 
                    <div className='video-play-pause'>
                        {playing ? (
                            <span className='pause-video' onClick={() => videoHandler("pause")}><span className='video-state-text'>Pause Video</span></span> 
                            
                        ) : (
                            <span className='play-video' onClick={() => videoHandler("play")}><span  className='video-state-text'>Play Video</span></span>
                           
                        )}
                    </div>  
                </div>
            </div> 
            <div className='video-time' id="video-time">
                <span className="controlsTime" progress>
                    {Math.floor(currentTime / 60) + ":" + ("0" + Math.floor(currentTime % 60)).slice(-2)}
                </span>
            </div>
            <h1 className="sub-title" id='video-subtitle'>{props.subTitle}</h1>
            <p className="details">{props.description}</p>            
        </div>
    );
}

// props :
// introP - image1 - image2 - quote - textP