import './innerBlog.css';
import BgView from '../../components/layouts/bgView/bgView';
import bgBlog from '../../assets/images/blogs/bg.jpg';
import { useParams as params } from "react-router-dom";
import { blogs } from "../../constants/blogs";
// Page parts
import LeftPart from './leftPartBlog/leftPartBlog';
import RightPart from './rightPartBlog/rightPartBlog';
import BottomPart from './bottomPartBlog/bottomPartBlog';
import Header from '../../components/layouts/header/header';

export default function InnerBlog() {

    const { blog_id } = params();
    const blogFiltered = blogs.find(blog => String(blog.id) === blog_id);

    const currentUrl = window.location.href;

    return (
        <div className="InnerBlog">
            <Header />
            <BgView titleClass="blog-detail" img={bgBlog} title="Our blogs" dark={false} imgTitle="" /> 
                <div className='blog-detail'> 
                    <LeftPart blogger={blogFiltered.blogger} publishDate={blogFiltered.date} featuredBlog={blogs} sharedURL={currentUrl} /> 
                    <RightPart quote={blogFiltered.more.right.quote} title={blogFiltered.title} introP={blogFiltered.more.right.introP} image1={blogFiltered.more.right.image1} image2={blogFiltered.more.right.image2} textP={blogFiltered.more.right.textP}/>
                    <div className='mob-left'>
                        <LeftPart blogger={blogFiltered.blogger} publishDate={blogFiltered.date} featuredBlog={blogs} sharedURL={currentUrl} /> 
                    </div>
                </div>
            <BottomPart />
        </div>
    );
}