import './partners.css';
import React, { useState } from 'react';
import BgView from '../../components/layouts/bgView/bgView.js';
import ButtonSimple from '../../components/form/inputs/buttonSimple/buttonSimple.js';
import SponsorForm from '../../components/form/forms/sponsorForm/sponsorForm.js';
import bgPartners from '../../assets/images/partners/bg.jpg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { partners } from '../../constants/partners';
import Header from '../../components/layouts/header/header';

export default function Partners() {

    const [lgShow, setLgShow] = useState(false);

    return (
        <div className="Partners">
            <Header />
            <BgView titleClass="partner-title" img={bgPartners} title="Partners" dark={false} imgTitle="" />
            <div className='container-list'>
                <p>By 2050, 1 in 4 people will be African. Initiatives like Root Studios help prepare the world for this overwhelming quantity of human capital. We welcome partners who can help us by providing resources for our cohort to complete their classes and connect our graduates with communities for job seekers. Please get in touch if your organization can provide technological resources, teaching staff, mentors, or internship opportunities for our graduates.</p>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <ul>
                            {partners.map((data) => (
                                <li key={data.id}>
                                    <Row>
                                        <Col xs={4} md={4} lg={4} className='align-self-center p-0'>
                                            <div className='logo d-flex justify-content-center align-items-center'>
                                                <img src={data.image} width='' height='' alt='Partner' />
                                            </div>
                                        </Col>
                                        <Col xs={8} md={8} lg={8} className='align-self-center p-0'>
                                            <h6 >{data.title}</h6>
                                        </Col>
                                    </Row>
                                </li>
                            ))}
                        </ul>
                        <ButtonSimple text='Become a sponsor' link='' click={() => setLgShow(true)} />
                        <Modal dialogClassName="cs-sponsor-width"
                            className="cs-sponsor-modal"
                            show={lgShow}
                            onHide={() => setLgShow(false)}>
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                                <SponsorForm />
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>
            </div>
        </div>
    );
}