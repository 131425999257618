// Items files importations
import graduate1 from '../assets/images/graduates/graduate-1.jpg';
import graduate2 from '../assets/images/graduates/graduate-2.jpg';
import graduate3 from '../assets/images/graduates/graduate-3.jpg';
import graduate4 from '../assets/images/graduates/graduate-4.jpg';
import graduate5 from '../assets/images/graduates/graduate-5.jpg';

// import images of graduates detail
// graduate-1
import graduate1_1 from '../assets/images/posts/posts_detail/post-1/img-1.jpg';
import graduate1_2 from '../assets/images/posts/posts_detail/post-1/img-2.jpg';
import graduate1_3 from '../assets/images/posts/posts_detail/post-1/img-3.jpg';
import graduate1_4 from '../assets/images/posts/posts_detail/post-1/img-4.jpg';
// graduate-2
import graduate2_1 from '../assets/images/posts/posts_detail/post-2/img-1.jpg';
import graduate2_2 from '../assets/images/posts/posts_detail/post-2/img-2.jpg';
import graduate2_3 from '../assets/images/posts/posts_detail/post-2/img-4.jpg';
import graduate2_4 from '../assets/images/posts/posts_detail/post-2/img-3.jpg';
// graduate-3
import graduate3_1 from '../assets/images/posts/posts_detail/post-3/img-1.jpg';
import graduate3_2 from '../assets/images/posts/posts_detail/post-3/img-2.jpg';
import graduate3_3 from '../assets/images/posts/posts_detail/post-3/img-3.jpg';
import graduate3_4 from '../assets/images/posts/posts_detail/post-3/img-4.jpg';
// graduate-4
import graduate4_1 from '../assets/images/posts/posts_detail/post-4/img-1.jpg';
import graduate4_2 from '../assets/images/posts/posts_detail/post-4/img-2.jpg';
import graduate4_3 from '../assets/images/posts/posts_detail/post-4/img-4.jpg';
import graduate4_4 from '../assets/images/posts/posts_detail/post-4/img-3.jpg';
// graduate-5
import graduate5_1 from '../assets/images/posts/posts_detail/post-5/img-1.jpg';
import graduate5_2 from '../assets/images/posts/posts_detail/post-5/img-2.jpg';
import graduate5_3 from '../assets/images/posts/posts_detail/post-5/img-4.jpg';
import graduate5_4 from '../assets/images/posts/posts_detail/post-5/img-3.jpg';


// import SDGs images
import sdg_n1 from '../assets/images/posts/posts_detail/sdgs/sdg-1.png';
import sdg_n4 from '../assets/images/posts/posts_detail/sdgs/sdg-2.png';
import sdg_n8 from '../assets/images/posts/posts_detail/sdgs/sdg-3.png';
import sdg_n9 from '../assets/images/posts/posts_detail/sdgs/sdg-4.png';
import sdg_n10 from '../assets/images/posts/posts_detail/sdgs/sdg-5.png';
import sdg_n11 from '../assets/images/posts/posts_detail/sdgs/sdg-6.png';
import sdg_n12 from '../assets/images/posts/posts_detail/sdgs/sdg-7.png';

export const graduates = [
   
    {
        id: 2,
        image: graduate2,
        title: "DIAMOND CUTTing",
        more: {
            left: {
                sdgs: [
                    {
                        id: 1,
                        image: sdg_n1,
                    },
                    {
                        id: 2,
                        image: sdg_n4,
                    },
                    {
                        id: 3,
                        image: sdg_n8,
                    },
                    {
                        id: 4,
                        image: sdg_n9,
                    },
                    {
                        id: 5,
                        image: sdg_n10,
                    }
                ]
            },
            right: {
                introP: "<p>Root Studios will open a diamond cutting shop where graduates can teach new cutters. Graduates may also seek commissions from local artisanal miners, who may choose to pay graduates in the shop to cut their diamonds helping the artisanal miner to achieve a better value for their diamonds.. </p><p>Graduates will also have access to laptops and the internet. They may deploy their entrepreneurial skills to create their own diamond brands, giving them the ability to sell gems on the international market.</p>",
                image1: graduate2_1,
                image2: graduate2_2,
                image3: graduate2_3,
                image4: graduate2_4,
                quote: "Cutting graduates can also use their new knowledge and skills to climb higher in the pre-existing mining industry. Students will gain capacities that will make them excellent candidates for management, leadership, and advisory positions that local people have historically been excluded from. Gaining influence amongst mining companies will also local people to have a greater influence over the mining operations that occur in their locality.",
            },
            bottom: {
                career: {
                    textP: "Finally, cutting graduates may use their gained knowledge of the diamond mining industry to access management positions within diamond companies that local people have historically been excluded from.",
                    careerList: [
                        "Diamond Cutter",
                        "Manufacturer",
                        "Machine Operator",
                        "Jeweler",
                        "Lab Gemologist",
                        "Gemstone Appraiser",
                        "Diamond Cut Designer",
                        "Wholesale Gemstone Buyer"
                    ],
                },
                cohortList: [
                   
                ]
            }
        }
    },
    {
        id: 3,
        image: graduate3,
        title: "UI/UX DESIGN",
        more: {
            left: {
                sdgs: [
                    {
                        id: 1,
                        image: sdg_n4,
                    },
                    {
                        id: 2,
                        image: sdg_n8,
                    }
                ]
            },
            right: {
                introP: "<p>Digital design graduates of Root Studios will be given access to laptops and the internet, from which they may compete for work on freelancing platforms such as BeHance, Upwork, and PeoplePerHour.</p><p>Graduates may also apply for opportunities within Root Studios, teaching design and entrepreneurship skills to future intakes. Finally, they may apply for design opportunities within our partner organizations.</p>",
                image1: graduate3_1,
                image2: graduate3_2,
                image3: graduate3_3,
                image4: graduate3_4,
                quote: "During the course, students will work on a range of hands-on projects. Creating quality work during these assignments will allow graduates to populate their portfolios. They can show these to prospective employers as examples of their skills, increasing their chances of accessing opportunities. Furthermore, they will gain certificates of proficiencies across commonly used design software, such as Figma. These skills are crucial prerequisites for any job in the UX/UI design sector."
            },
            bottom: {
                career: {
                    textP: "Finally, they may apply for design opportunities within our partner organizations.",
                    careerList: [
                        "UX Designer",
                        "UI Designer",
                        "Design Researcher",
                        "Information Architect",
                        "Usability Tester",
                        "Analyst",
                        "Product Designer: Digital",
                        "Design Lead",
                    ],
                },
                cohortList: [
                    
                ]
            }
        }
    },
    {
        id: 4,
        image: graduate4,
        title: "MINERALS DESIGN",
        more: {
            left: {
                sdgs: [
                    {
                        id: 1,
                        image: sdg_n4,
                    },
                    {
                        id: 2,
                        image: sdg_n9,
                    },
                    {
                        id: 3,
                        image: sdg_n11,
                    },
                    {
                        id: 4,
                        image: sdg_n12,
                    }
                ]
            },
            right: {
                introP: "<p>Material produce design graduates may use Root Studios’ facilities to continue to design for their communities unique needs by experimenting with the alternative uses for the resources found in situ.</p><p>They will also learn a range of highly-employable skills. They will learn the fundamentals of minerals and materials, and understand how these can be engineered to achieve high performance. </p>",
                image1: graduate4_1,
                image2: graduate4_2,
                image3: graduate4_3,
                image4: graduate4_4,
                quote: "These skills are required by all businesses and organizations that create and work with physical products. This includes small and medium enterprises (SMEs), start-ups, corporations, and academic research groups. Our Minerals Design class is also a great launch pad for further academic study in fields such as engineering.",
            },
            bottom: {
                career: {
                    textP: "Finally, cutting graduates may use their gained knowledge of Mineral Design industry to access management positions within industries.",
                    careerList: [
                        "Product Designer: Material",
                        "Manufacturer",
                        "Design Entrepreneur",
                        "Design Lead",
                        "Materials Engineer",
                        "CAD Technician",
                        "Product Manager",
                        "Entrepreneur",
                    ],
                },
                cohortList: [
                   
                ]
            }
        }
    },
    {
        id: 5,
        image: graduate5,
        title: "ENTREPRENEURSHIP",
        more: {
            left: {
                sdgs: [
                    {
                        id: 1,
                        image: sdg_n1,
                    },
                    {
                        id: 2,
                        image: sdg_n4,
                    },
                    {
                        id: 3,
                        image: sdg_n8,
                    },
                    {
                        id: 4,
                        image: sdg_n9,
                    },
                    {
                        id: 5,
                        image: sdg_n11,
                    }
                ]
            },
            right: {
                introP: "<p>Graduates will be empowered to leverage their new skills to begin place-based and eco-friendly enterprises within their communities, or within international marketplaces via online platforms. To help graduates to launch these enterprises, Root Studios will offer grants and funding that alumni will be invited to pitch for.</p>",
                image1: graduate5_1,
                image2: graduate5_2,
                image3: graduate5_3,
                image4: graduate5_4,
                quote: "Some graduates may decide to look for work opportunities externally. They can utilize their new skills in management, finance, marketing, and sales to work for all manner of businesses in a range of positions. These range from start-ups in their locality, to national SMEs, to international corporations. It will allow them to join the international community of professionals working in Fourth Industrial Revolution roles.",
            },
            bottom: {
                career: {
                    textP: "Finally, they may apply within our partner organizations.",
                    careerList: [ 
                        "Entrepreneur",
                        "Budget Analyst",
                        "Financial Manager",
                        "HR Manager",
                        "Public Relations Manager",
                        "Systems Analyst",
                        "Marketing Manager",
                        "Sales Operative", 
                    ],
                },
                cohortList: [
                   
                ]
            }
        }
    },
    {
        id: 1,
        image: graduate1,
        title: "JEWELRY DESIGN",
        more: {
            left: {
                sdgs: [
                    {
                        id: 1,
                        image: sdg_n4,
                    },
                    {
                        id: 2,
                        image: sdg_n12,
                    }
                ]
            },
            right: {
                introP: "<p>Graduates of the jewelry design course will possess a strong understanding of jewelry design and manufacturing methods, techniques, and technology. Furthermore, they will have the research skills required to enter as a specialist into any design or technology within the jewelry design field. They may also collaborate with graduates from other classes to create their own jewelry business, leveraging technology to attract an international consumer base.</p>",
                image1: graduate1_1,
                image2: graduate1_2,
                image3: graduate1_3,
                image4: graduate1_4,
                quote: "These skills will be applicable in real-life work situations, and graduates will be able to use their portfolio formed during the course to access opportunities within the jewelry industry. They can also utilize their new CAD skills to develop prototypes for the product design industry as a technician or designer.",
            },
            bottom: {
                career: {
                    textP: "These skills will be applicable in real-life work situations, and graduates will be able to use their portfolio formed during the course to access opportunities within the jewelry industry.",
                    careerList: [ 
                        "Jewelry Designer",
                        "Jewelry Seller",
                        "CAD Technician",
                        "Accessories Developer",
                        "CAD Production Designer",
                        "Jewelry Retail Manager",
                        "Jewelry Entrepreneur",
                        "Merchandise Designer",

                    ],
                },
                cohortList: [
                 
                ]
            }
        }
    },
];