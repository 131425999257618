import './register.css';
import BgView from '../../components/layouts/bgView/bgView.js';
import RegisterForm from '../../components/form/forms/registerForm/registerForm.js';
import bgRegister from '../../assets/images/register/bg.jpg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../../components/layouts/header/header';

export default function Register() {
    return (
        <div className="Register">
            <Header />
            <BgView titleClass="register-title" img={bgRegister} title="Register" dark={false} imgTitle="" />
            <Row className='content justify-content-between'>
                <Col   className='infos'>
                    <h4>INTERESTED IN OUR CLASSES?</h4>
                    <p className='mb-2'>“Natin for us, wey we no dae dae - Nothing for us without us"</p>
                    <p className='mb-2'>Interested in our classes?</p>
                    <p>Register for any of our classes to start your skills development journey. We endeavor to respond to your interest as soon as possible.</p>
                </Col>
                <Col   className='form'>
                    <RegisterForm />
                </Col>
            </Row>
        </div>
    );
}