// Items files importations
import mentor1 from '../assets/images/mentors/mentor-1.jpg';
import mentor2 from '../assets/images/mentors/mentor-2.jpg';
import mentor3 from '../assets/images/mentors/mentor-3.jpg';
import mentor4 from '../assets/images/mentors/mentor-4.jpg';
import mentor5 from '../assets/images/mentors/mentor-5.jpg';
import mentor6 from '../assets/images/mentors/mentor-6.jpg';

export const mentors = [
    {
        id: 1,
        image: mentor1,
        name: "Fas lebbie",
        profile: "Founder, Design, Entrepreneurship and Innovation Instructor",
        linkedin: "https://www.linkedin.com",
        email: "example@gmail.com",
        location: "New York, NY, USA",
        intro: "<p>We are led by our Founder Fas Lebbie, a designer, entrepreneur, and technologist who was born and raised in Sierra Leone before immigrating to America. He has the experience, education, and purpose to strive to make a small impact within natural  resource systems in Africa. </p><p> He is currently a Teaching Fellow and Ph.D. candidate in <a href='https://design.cmu.edu/content/phd' target='_blank'><span>Transition Design</span></a> at Carnegie Mellon. Fas also manages a team of product designers at Meta. Furthermore, he collaborates with MIT as a Government Innovation Mentor, working with design researchers in West African governance to develop policy and innovative programs.</p><p>As a PhD candidate, Fas’ research begins by investigating African extraction sites and implications of these minerals in green technologies. His works builds on theories of post extractive imaginaries to offer new interventions for sustainable development & the diversification of natural resources through investing in downstream linkages </p><p>Fas works with artisanal diamond mining communities in Kono District, Sierra Leone, through his up-and-coming social enterprises. <a href='https://rootdiamonds.com/' target='_blank'><span>Root Diamonds</span></a>, as highlighted by <a href='https://issuu.com/newschool/docs/parsons-red-2022?fr=sZGNhOTQwMTQxOTM' target='_blank'><span>Parsons School of Design</span></a>, connects consumers of diamonds to artisanal miners. Revenues from this diamond company will fund this Root Studios intiatives</p>",
        education: "<ul><li>Currently studying for PhD Transition Design - Carnegie Mellon University (Present).</li><li>MS in Strategic Design and Management - Parsons School of Design: The New School (2021).</li><li>Fellowship Program in Impact Entrepreneurship - Parsons School of Design (2020).</li><li>Certificate in UX Design AR and VR - NYU Tandon School of Engineering (2020).</li><li>BA in Entrepreneurship/Entrepreneurial Studies and International Studies with Concentration in Pre Med - University of Utah (2018).</li></ul>"
    },
    {
        id: 2,
        image: mentor2,
        name: "Maarten De Witte",
        profile: "Diamond Cutting & Grading Instructor",
        linkedin: "https://www.linkedin.com",
        email: "example@gmail.com",
        location: "New York, NY, USA",
        intro: "<p>Maarten de Witte has been intimately involved with cutting gem diamonds for 50 years. Having learned his trade at the American School of Diamond Cutting, he eventually became its Director, and is known to many as the Diamond Wizard™.</p><p>Maarten’s experience ranges from the solitary workbench to the global diamond industry. He was instrumental in building the Hearts On Fire Company, from initial start-up to international brand. He is devoted to excellence as a craftsman, innovator, and teacher – in wholesale, retail, branding, training, research and/or development of new cutting styles.</p>",
        education: "<ul><li>Graduate Gemologist - Gemological Institute of America (2003).</li><li>MS in Minerals Geography - University of Illinois (1989).</li><li>BA in African History – University of Illinois (1986).</li><li>Certified Gemologist – American Gem Society (1984).</li></ul>"
    },
    {
        id: 3,
        image: mentor3,
        name: "Mickey Brookshire",
        profile: "Diamond Cutting & Grading Instructor",
        linkedin: "https://www.linkedin.com",
        email: "example@gmail.com",
        location: "New York, NY, USA",
        intro: "<p>Mickey began cutting diamonds in 1972. From his small cutting shop in Scottsdale, Arizona he manufactures rough and specializes in repairing damaged stones and recutting older styles to modern proportions. He buys second hand finished stones as well which are often recut and resold to the trade.</p><p>From 1978 to the present he has been dealing in rough and mining alluvial deposits in the Mano River Union Countries of Sierra Leone, Liberia and Guinea.</p><p>In his efforts to build a more environmentally conscientious and sustainable business in West Africa he now focuses his attention on low-impact dredging and customizing the cutting process. By leaving a small footprint on the environment and maximizing the beauty and value of the rough he has been able to better give back to the people and the communities that have been instrumental to his growth.</p><p>He now mines select alluvial sites and produces signature cuts that respect the individuality of each piece of rough.</p>",
        education: "<ul><li>Rough Diamond Certificate - International Gemological Institute: Antwerp, Belgium (1998)</li><li>GIA Diamond Certificate (1977)</li><li>American School of Diamond Cutting (1971-1974)</li><li>Community College Graduate (1970)</li><li>High School Graduate (1963)</li></ul>"
    },
    {
        id: 4,
        image: mentor4,
        name: "Ty Brookshire",
        profile: "Diamond Cutting Instructor",
        linkedin: "https://www.linkedin.com",
        email: "example@gmail.com",
        location: "New York, NY, USA",
        intro: "<p>Ty Brooks is a diamond cutter and apprentice of Mickey Brookshire. He has been training in diamond cutting under Mickey’s guidance for the past five years. He has been involved in the African mineral resources industry for twelve years.</p><p>He joins Mickey to teach diamond cutting, giving students the beginner skills during the first few weeks of the diamond cutting class. The initial stage led by Ty will give students the academic and technological ( practical ) proficiencies required to learn more advanced skills from Mickey.</p>",
        education: "<ul><li>ISSA Personal Training Certificate (2018)</li><li>Small-Scale Alluvial Diamond and Gold Mining Apprenticeship  - Sierra Leone, Liberia, and Guinea (2012-2017)</li><li>Diamond Cutting and Trading Entrepreneurship (2008-2011)</li><li>Diamond and Diamond Grading Certificate  - International Gemological Services (2010)</li><li>Community College Graduate (2007)</li><li>High School Graduate (2004)</li></ul>"
    },
    {
        id: 5,
        image: mentor5,
        name: "David Mashiane",
        profile: "Jewelry Design Instructor",
        linkedin: "https://www.linkedin.com",
        email: "example@gmail.com",
        location: "New York, NY, USA",
        intro: "<p>David Mashiane has worked at the coalface of jewelry CAD designer, and jewelry manufacturing for 8 years. Working with manufacturers and retailers of a range of scales, David has mastered the trade of designing and making jewelry, and has achieved a multitude of accolades in recognition of his achievements. In 2008, he won an international fine art painting competition under Motheo FET college. His chandelier designed in 2011 won in a competition at the Thuthuka jewelry awards. Finally, in 2013 David became a finalist in a PPC jewelry competition for a piece he created under the Tshwane University of Technology. David is thrilled by the prospect of passing on his immense talent, experience, and skills to Africa’s youth.</p>",
        education: "<ul><li>BA in Jewelry Design - Tshwane University of Technology.</li><li>Diploma - Central University of Technology </li><li>Qualification In Visual Art & N4 Jewelry Design - Motheo College.</li><li>Qualification in Marketing Research and Customer Service from Dumellin College.</li><li>Intro in Web & Graphic Design - Boston College, </li><li>Product CAD Design - design@50.</li><li>Goldsmith Qualification - Shadows Jewelry Company</li></ul> "
    },   
    {
        id: 6,
        image: mentor6,
        name: "Dr. Josephus Jeremiah Brimah",
        profile: "Chief Technical and Higher Education Officer",
        linkedin: "https://www.linkedin.com",
        email: "example@gmail.com",
        location: "New York, NY, USA",
        intro: "<p>Dr. Brimah is a Sierra Leonean academic with good analytical, reviewing, editing and report writing skills. He is credited as a professional communicator, negotiator and a team player with an ability to work effectively with members of diverse national and cultural backgrounds.</p><p>He is specialized in Logic, Epistemology, Ethics and Bioethics, Human Rights, and Law. He has great experience in the teaching and lecture field in Administrative Law, Law of Contract, Business Law, Educational Administration (Law and Policy), Philosophy of Education, Comparative Education and Bioethics and Law at Postgraduate levels. He is the developer of the current law curriculum for the establishment of the Department of Law, Njala University (Bo Campus).</p><p>He has served as Director of Thinking Pink Breast Cancer Foundation and as Chief Examiner at the West African Examinations Council (WAEC), Freetown.</p>",
        education: "<ul><li>Master of Arts (Law)  - University of Sheffield, Sheffield, United Kingdom (2008-2010)</li><li>Doctor of Philosophy (Bioethics, Ethics) - University of Manchester, United Kingdom (2003-2006)</li><li>Master of Arts (Philosophy) - University of Sierra Leone (1996-1999)</li><li>Bachelor of Arts (Magna Cum Laude) (Philosophy, Theology) - St. Paul's College and Seminary, Gbarnga, Liberia (1987-1991).</li></ul>"
    }
];