import './bottomPartGateway.css';
import ButtonSimple from '../../../components/form/inputs/buttonSimple/buttonSimple.js';

import { Link } from "react-router-dom";

export default function BottomPartGateway(props) {

    return (
        <div className="BottomPartGateway">
            <div className='donation'>
                <h3>Make a donation</h3>
                <p  className='mb-5'>Root Studios is a 501(c)(3), meaning we cannot turn a profit from our operations. We rely upon charitable donations to continue helping African youths to compete on the global landscape through job-ready programs. Without your contributions, the vast human capital potential in the communities we work with will remain untapped, and they will continue to rely on resource extraction and the detriment of people’s well-being and ecosystems.</p>
                <ButtonSimple text='Make a Donation' link='/' /> 
            </div>
            <div className='next-post'>
                <p>CONTINUE TO SCROLL AND</p>
                <Link to={`/gateway/${props.idNext}`} replace>
                    <p>READ ABOUT THE NEXT CLASS</p>
                </Link>
                <div className='post'>
                    <Link to={`/gateway/${props.idNext}`} replace>
                        <figure>
                            <img src={props.imageNext} width="" height="" alt="Post" />
                        </figure>
                        <h1>{props.titleNext}</h1>
                    </Link>
                </div>
            </div>
        </div >
    );
}

// props :
// idNext - imageNext - titleNext