import './graduate.css'; 
import { useParams as params } from "react-router-dom";
import { graduates } from "../../constants/graduates"; 
// Page parts
import TopPart from './topPartGraduate/topPartGraduate';
// import LeftPart from './leftPartGraduate/leftPartGraduate';
import RightPart from './rightPartGraduate/rightPartGraduate';
import BottomPart from './bottomPartGraduate/bottomPartGraduate';

import Header from '../../components/layouts/header/header';

export default function Graduate() {

    const { graduate_id } = params();
    const graduateFiltered = graduates.find(graduate => String(graduate.id) === graduate_id);

    return (
        <div className="Graduate">
            <Header />
            <TopPart  image={graduateFiltered.image} title={graduateFiltered.title} />
            <div className='graduate-details-outer'>
                {/* <Row>
                    <Col xs={12} md={12} lg={3}>
                        <LeftPart trainer={graduateFiltered.more.left.trainer} sdgs={graduateFiltered?.more?.left?.sdgs} />
                    </Col> */}
                    {/* <Col xs={12} md={12} lg={9}> */}
                        <RightPart introP={graduateFiltered.more.right.introP} image1={graduateFiltered.more.right.image3} image2={graduateFiltered.more.right.image4} quote={graduateFiltered.more.right.quote} textP={graduateFiltered.more.right.textP} />
                    {/* </Col>
                </Row> */}
            </div>
            <BottomPart careerP={graduateFiltered.more.bottom.career.textP} careerList={graduateFiltered.more.bottom.career.careerList} cohortList={graduateFiltered.more.bottom.cohortList} />
        </div> 
    );
}