import '../forms.css'; 
import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'; 
import ButtonSubmit from '../../inputs/buttonSubmit/buttonSubmit.js';

export default function SponsorForm() {

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <div className="SponsorForm">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Form.Group as={Col} xs="12" sm="12" md="12" xl="12" lg="12" controlId="validation01">
            <Form.Control
              type="text"
              placeholder="Full Name"
              pattern="[A-Za-z]{1,}"
            />
            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please enter a valid full name.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row >
          <Form.Group as={Col} xs="12" sm="12" md="12" xl="12" lg="12" controlId="validation02">
            <Form.Control
              required
              type="email"
              placeholder="Email *"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            />
            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please enter a valid email.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row >
          <Form.Group as={Col} xs="12" sm="12" md="12" xl="12" lg="12" controlId="validation03">
            <Form.Control
              type="text"
              placeholder="Subject"
              pattern="[A-Za-z]{1,}"
            />
            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please enter a valid subject.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row >
          <Form.Group as={Col} xs="12" sm="12" md="12" xl="12" lg="12" controlId="validation04">
            <Form.Control
              as="textarea"
              placeholder="Message"
              pattern="[A-Za-z0-9]{5,}"
              rows="1"
            />
            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please enter a valid message.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <ButtonSubmit text="Submit" />
      </Form>
    </div>
  );
}