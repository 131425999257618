import './bgView.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

export default function BgView(props) {
    return (
        <div className='BgView' >
            {props.imgTitle.length === 0 ? null : <h4>{props.imgTitle}</h4>}
            <LazyLoadImage
                alt="BG"
                src={props.img}
                effect="black-and-white"
                placeholderSrc="black-and-white"
                threshold={200}
            />
            {props.dark === true ? <div className="layer"></div> : null}
            <h1  className={props.titleClass }   >{props.title}</h1>
        </div>
    );
}
 