import './about.css'; 
import BgView from '../../components/layouts/bgView/bgView.js';
import BodyPart from './bodyPart/bodyPart.js';
import FooterPart from './footerPart/footerPart.js'; 
import bgAbout from '../../assets/images/about/bg.jpg'; 
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Header from '../../components/layouts/header/header';

export default function About() {
    return (
        <div className="About">
            <Header />
            <BgView titleClass="about-title" img={bgAbout} title="About us" dark={true} imgTitle="Equity centered + Placebase learning" />
            <div className='content'>

            <h5>The Story of Root Studios</h5>
            <p>Fas Lebbie founded Root Studios during his ongoing Ph.D. research at Carnegie Mellon University. His research looks at post-extractivist imaginaries to investigate economic linkages between resource revenue and human capital development. Post-extractivism seeks alternative forms of development that ensure zero worlds are destroyed by looking beyond growth-oriented capitalism.</p>
            <p>This research inspired Fas to create interventions for extractive economies via impact-first social enterprises. He aims to change how we design for extractive economies by going beyond creating better value in the supply chain for mining communities by using resource revenues for sustainable development.</p>
            <h5>Vision</h5>
            <p>By 2051, ¼ of the world’s population will be African. The continent urgently requires pathways for its massive resource revenues to contribute to human capital development so that its growing population can compete on the global landscape.</p>
            <p>Root Studios partners with its sister market-facing organization, Root Diamonds, to envision four stages to transitioning to this future of natural resources. </p>

            <ul className='decimal'>
                <li> Root Diamonds extracts diamonds sustainably while protecting life support systems.</li>
                <li>Communities are empowered by profits from their natural resources.</li>
                <li>Broadstream economic linkages are forged with Root Studios.</li>
                <li>Extractive economies are diversified, undoing social, economic, and environmental entrapment. Communities regain sovereignty to extract resources only when required to match local needs.</li>
                <li>Upholding and centering community rights </li>
            </ul>
            <h5>Ethos and Strategic Pillars</h5>

            <p>At Root Studios, we hold ourselves accountable to our ethos and the strategic pillars that underpin our operation.</p>

            <p>Ethos</p>
            <ul>
                <li>Decolonializing resource governance.</li>
                <li>Place-based resource empowerment. </li>
                <li>Upholding and centering community rights </li>
            </ul> 
            <p>Strategic Pillarsx</p>
            <ul>
                <li>Integrity and transparency into mineral resource supply chains.</li>
                <li>Redistribution of resource revenues for human capital development.</li>
            </ul>
            <h5>Beta In Sierra Leone</h5>
            <p>Sierra Leone is one of the largest producers of diamonds in Africa. However, since gaining its independence from the British empire in 1961, it has made little socio-economic progress and suffered from a devastating 11-year-long civil war. During the post-war period, the country continued to be plagued by crises, and the government was unable to provide human development opportunities: education and employment.<br></br> Representing 80% of the population, young people under the age of 35 are the most severely impoverished. They are the most likely to be employed as mining laborers, earning just $1 a day, but also have the greatest potential to transform Sierra Leone.</p>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <BodyPart />
                    </Col>
                </Row>
            </div>
            <FooterPart />
        </div>
    );
}