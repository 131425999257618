import './buttonSubmit.css'; 
import Button from 'react-bootstrap/Button';

export default function ButtonSimple(props) {
  return (
    <div className="ButtonSubmit">
      <Button type="submit">{props.text}</Button>
    </div>
  );
}

// props :
// text