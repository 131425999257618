export const contact = {
    email: "info@rootstudios.com",
    addr1: "Los Angeles, California",
    addr2: "",
    addrLink: "https://goo.gl/maps/qnP5TMbXfW2xPgkW9",
    phone: "123-456-7890",
    openingHours: [
        "Mon - Fri: 8am - 8pm",
        "Saturday: 9am - 7pm",
        "Sunday: 9am - 8pm"
    ]
}