import './mentorsTable.css'; 
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 
import { FaLinkedinIn, FaEnvelope } from "react-icons/fa"; 
import { PaginatedList } from 'react-paginated-list';
import Select from "react-select";
import Form from 'react-bootstrap/Form';

export default function MentorsTable(props) {
  const options = [
    { value: "diamond_cutting", label: "Diamond Cutting" },
    { value: "UI_UX_Design", label: "UI/UX Design" },
    { value: "Minerals_Design", label: "Minerals Design" },
    { value: "Entrepreneurship", label: "Entrepreneurship" },
    { value: "jewelry_design", label: "Jewelry Design" }
  ];
  return (
    <div className="MentorsTable Team">
      <p>{props.introP}</p>
    <div className='mentor-filter RegisterForm '> 
      <Form noValidate > 
          <Form.Group  className='form-group w-240' controlId="validation04">
            <Select
              className='select-input'
              classNamePrefix="select"
              options={options}
              isSearchable={false}
              isClearable={true}
              placeholder="Select ..."
              required
            /> 
          </Form.Group>   
      </Form>
      </div>
      <Row> 
        <Col xs={12} md={12} lg={12}>
           
          <ul  className='list-team'>
            <PaginatedList
              list={props.mentors}
              itemsPerPage={5}
              nextText='Next'
              prevText='Prev.'
              loopAround={false}
              displayNumbers={true}
              displayRange={1}
              isLoading={false}
              renderList={(list) => (
                <>
                  {list.map((data) => {
                    return (
                      <li key={data.id} className='member-team'>
                      <div className='member-img'>
                          <figure className='cover-img'>
                            <img src={data.image} width="" height="" alt='Mentor' />
                          </figure>
                      </div>
                      <div className='member-details'> 
                          <div className='member-info'> 
                              <h6> {data.name} </h6>
                              <p> {data.profile} </p>
                              <div className='social d-inline-flex'>
                                  <a href={data.linkedin} target='_blanck'><FaLinkedinIn size={15} /></a>
                                  <a href={`mailto:${data.email}`}><FaEnvelope size={15} /></a>
                              </div>
                          </div> 
                          <div className='learn-more  d-flex justify-content-end align-items-end'> 
                              <p> {data.location} </p>
                          </div>
                      </div> 
                  </li>
 
                    );
                  })}
                </>
              )}
            />
          </ul>
        </Col>
      </Row>
    </div>
  );
}

// props :
// introP - mentors