import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
// import gsap from "gsap";
import ScrollToTop from './components/others/scrollToTop/scrollToTop';

import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.Fragment>
    <Router>
      <ScrollToTop />
      <App />
    </Router>
  </React.Fragment>
);
