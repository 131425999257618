import '../forms.css'; 
import React, { useState, useRef } from 'react';
import Row from 'react-bootstrap/Row'; 
import Form from 'react-bootstrap/Form'; 
import ButtonSubmit from '../../inputs/buttonSubmit/buttonSubmit.js';
import emailjs from '@emailjs/browser';

export default function ContactForm() {
  const form = useRef();
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
     
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

   
  };

  const sendEmail = (e) => {   
    //e.preventDefault();
    if (e.currentTarget.checkValidity() === false) {
    emailjs.sendForm('service_rtvb8uk', 'template_kh6hbnh', form.current, 'ESgJULWW09fkxe6pt')
      .then((result) => {
          //console.log("MM");
         //e.preventDefault();
          //e.stopPropagation();
      }, (error) => {
          console.log(error.text);
      });
    }
    else
    {
      emailjs.sendForm('service_rtvb8uk', 'template_kh6hbnh', form.current, 'ESgJULWW09fkxe6pt')
      .then((result) => {
          console.log(result.text);
         e.preventDefault();
          e.stopPropagation();
      }, (error) => {
          console.log(error.text);
      });
    }

    setValidated(true);
  };


  return (
    <div className="ContactForm">
      <Form ref={form} noValidate validated={validated} onSubmit={sendEmail}>
        <Row className='flex-wrap justify-content-between'>
          <Form.Group  className='form-group w-240' controlId="validation01">
            <Form.Control
              type="text" 
              placeholder="First name"
              pattern="[A-Za-z]{1,}"
              name="first_name"
            />
            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please enter a valid first name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group  className='form-group w-240' controlId="validation02">
            <Form.Control
              type="text"
              placeholder="Last name" 
              pattern="[A-Za-z]{1,}"
              name="last_name"
            />
            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please enter a valid last name.
            </Form.Control.Feedback>
          </Form.Group> 
          <Form.Group  className='form-group w-240' controlId="validation03">
            <Form.Control
              required
              type="email"
              className='mt-15'
              placeholder="Email *"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              name="email_id"
            />
            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please enter a valid email.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group  className='form-group w-240' controlId="validation04">
            <Form.Control
              type="tel"
              className='mt-15'
              placeholder="Phone"
              pattern='[0-9+]{10,}'
              name="phone_number"
            />
            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please enter a valid phone.
            </Form.Control.Feedback>
          </Form.Group> 
          <Form.Group className='form-group w-100 mb-40' controlId="validation05">
            <Form.Control
              as="textarea"
              placeholder="Message"
              className='mt-40'
              pattern="[A-Za-z0-9]{5,}"
              rows="1"
              name="message_details"
            />
            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please enter a valid message.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <ButtonSubmit text="Submit"
              />
      </Form>
    </div>
  );
}