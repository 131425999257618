import './rightPartBlog.css'; 
import { IoMdQuote } from "react-icons/io";
import { Markup } from 'interweave';

export default function RightPartBlog(props) {

    return (
        <div className="RightPartBlog">
            <div className='quote'>
                <IoMdQuote size={16} />
                <p>{props.quote}</p>
            </div>
            <h5>{props.title}</h5>
            <Markup content={props.introP} />
            <div className='imgs'>
                <div className='img-left'>
                    <figure>
                        <img src={props.image2} width="" height="" alt="EPD left" />
                    </figure>
                </div>
                <div className='img-right'>
                    <figure>
                            <img src={props.image1} width="" height="" alt="EPD right" />
                    </figure>
                </div>
            </div>
            <Markup content={props.textP} />
        </div>
    );
}

// props :
// quote - title - introP - image1 - image2 - textP