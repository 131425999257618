import './graduates.css';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { graduates } from "../../constants/graduates";
import Header from '../../components/layouts/header/header';

export default function Graduates() {
    return (
        <div className="Graduates">
            <Header />
            <div className='graduate-outer'>
                <Row>
                    <Col className='intro-part'>
                        <h2>Our graduates</h2>
                        <p>Root Studios’ graduates will finish their courses empowered to simultaneously add value to mined and recovered resources, and access Fourth Industrial Revolution opportunities that are currently out of reach.</p>
                        <p>The institution will therefore generate a community of African future leaders who represent a continent of multiple ethnicities, gender, and levels of disability.</p>
                    </Col>
                    <Col className='posts-part'>
                        <ul className='posts-circle'>
                            {graduates.map((data) => (
                                <li className='post' key={data.id}>
                                    <Link to={`/graduate/${data.id}`}>
                                        <figure className='cover-img'>
                                            <img src={data.image} width='' height='' alt='' />
                                        </figure>
                                        <h6>{data.title}</h6>
                                        <p>Learn more</p>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </Col>
                </Row>
            </div>
        </div>
    );
}