import './blogs.css'; 
import { Link } from 'react-router-dom'; 
import BgView from '../../components/layouts/bgView/bgView.js';
import bgBlog from '../../assets/images/blogs/bg.jpg';
import { PaginatedList } from 'react-paginated-list';
import { blogs } from "../../constants/blogs";
import Header from '../../components/layouts/header/header'; 

export default function Blogs() {
    return (
        <div className="Blogs">
            <Header />
            <BgView titleClass="blog-title" img={bgBlog} title="Our Blogs" dark={false} imgTitle="" /> 
            <div className='blog-outer'> 
                <p>Keep up with Root Studios latest work by reading our blogs.</p>
                <ul className='list-blogs'> 
                    <PaginatedList
                        list={blogs}
                        itemsPerPage={4}
                        nextText='Next'
                        prevText='Prev.'
                        loopAround={false}
                        displayNumbers={true}
                        displayRange={1}
                        isLoading={false}
                        renderList={(list) => (
                            <>
                                {list.map((data) => {
                                    return (
                                        <li key={data.id} className='item-blog'>
                                            <div className='blog-thumbnail'>
                                                <figure className='cover-img'>
                                                    <img src={data.image} width="" height="" alt='Blog' />
                                                </figure>
                                            </div>
                                            <div className='blog-short-description'>
                                                <div className='title'>
                                                    <h6> {data.title} </h6>
                                                    <p> {data.blogger} </p>
                                                </div>
                                                <div className='description'>
                                                    <p className='more-infos'>
                                                        {data.introText}
                                                        <Link to={`/blog/${data.id}`}>read more</Link>
                                                    </p>
                                                    <p className='date'>{data.date}</p>
                                                </div>
                                            </div> 
                                        </li>
                                    );
                                })}
                            </>
                        )}
                    />
                </ul>  
            </div>
        </div>
    );
}