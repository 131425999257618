import './leftPartGateway.css';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 
import { Markup } from 'interweave';
export default function LeftPartGateway(props) {

    const [show, setShow] = useState(false);

    return (
        <div className='LeftPartGateway'>
            <div className='part-1'>
                <h6>Class trainer</h6>
                <h4>{props.trainer}</h4>
                <p className={props.activeCs} onClick={() => setShow(true)}>view curriculum</p>
            </div>
            <div className='part-2'>
                <h6>SDGs</h6>
                <p >{props.sdgsText}</p>
                <hr />
                <div className='sdg-imgs'>
                    {props.sdgs.map((data) => (
                        <img key={data.id} src={data.image} width="" height="" alt="sdg" />
                    ))}
                </div>
            </div> 
            {/* Modal */}
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="cs-width"
                className="cv-details-modal">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12} md={12} lg={12} xl={12} className='cv-details'>
                            <div className='ux-uidesign' data-value={props.fullTextClass}>  
                                 <Markup content={props.fullText} />
                            </div>
                            <div className='titles-part'>
                                <h3>{props.modalTitle}</h3>
                                <h5>Three Months to Completion</h5>
                            </div>
                            <div className='list-part'>
                                <h6>Workshop goals:</h6>
                                <ul>
                                    {props.goalsList.map((data, idx) => (
                                        <li key={idx}>
                                            {data}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className='list-part'>
                                <h6>Workshop outline:</h6>
                                <ul>
                                    {props.outlineList.map((data, idx) => (
                                        <li key={idx}>
                                            {data}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className='list-part'>
                                <h6>Workshop standards:</h6>
                                <ul>
                                    {props.standardsList.map((data, idx) => (
                                        <li key={idx}>
                                            {data}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    );
}

// props :
// trainer - list - sdgs - modalTitle - goalsList - outlineList - standardsList