import './gateway.css'; 
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 
import { useParams as params } from "react-router-dom";
import { posts } from "../../constants/posts";
import { mentors } from "../../constants/mentors";  
// Page parts
import VideoPlayer  from './videoPlayerGateway/videoPlayerGateway';
import TopPart from './topPartGateway/topPartGateway';
import LeftPart from './leftPartGateway/leftPartGateway';
import RightPart from './rightPartGateway/rightPartGateway';
import BottomPart from './bottomPartGateway/bottomPartGateway';
import MentorsTable from './mentorsTable/mentorsTable';

import Header from '../../components/layouts/header/header';

export default function Gateway() {

    const { post_id } = params();
    const postFiltered_next = posts.find(post => String(post.id) === String(Number(post_id) + 1));
    const postFiltered = posts.find(post => String(post.id) === post_id);

    return (
        <div className="Gateway">
            <Header />
            {postFiltered.id === 0 ?
                <VideoPlayer
                    title={postFiltered.title}
                    subTitle={postFiltered.subTitle}
                    description={postFiltered.description}
                    video={postFiltered.videoLink}
                />
            :
                <TopPart
                    image={postFiltered.image}
                    title={postFiltered.title}
                />
            }

            {postFiltered.id === 6 ?
                <div className='container-list d-flex justify-content-center align-items-center'>
                    <MentorsTable
                        introP={postFiltered.more.right.introP}
                        mentors={mentors}
                    />
                </div>
                : postFiltered.id === 0 ? 
                    <div></div>
                :<div>
                    <div className='cs-container'>
                    <Row>
                        <Col className='cs-left-part' >
                            <LeftPart
                                activeCs={postFiltered.more.left.activeCs}
                                trainer={postFiltered.more.left.trainer}
                                cv={postFiltered}
                                sdgs={postFiltered?.more?.left?.sdgs}
                                sdgsText={postFiltered?.more?.left?.sdgsText}
                                modalTitle={postFiltered.more.left.cv.title}
                                goalsList={postFiltered.more.left.cv.goals}
                                outlineList={postFiltered.more.left.cv.outline}
                                standardsList={postFiltered.more.left.cv.standards}
                                fullTextClass={postFiltered.more.left.cv.fullTextClass}
                                fullText={postFiltered.more.left.fullText}
                            />
                        </Col>
                        <Col className='cs-right-part'>
                            <RightPart
                                introP={postFiltered.more.right.introP}
                                image1={postFiltered.more.right.image1}
                                image2={postFiltered.more.right.image2}
                                quote={postFiltered.more.right.quote}
                                textP={postFiltered.more.right.textP}
                            />
                        </Col>
                    </Row>
                    </div>
                    <BottomPart
                        idNext={postFiltered_next.id}
                        imageNext={postFiltered_next.image}
                        titleNext={postFiltered_next.title}
                    />
                </div>
            }
        </div>
    );
}