import './team.css'; 
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Markup } from 'interweave';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BgView from '../../components/layouts/bgView/bgView.js';
import bgTeam from '../../assets/images/team/bg.jpg';
import { FaLinkedinIn, FaEnvelope } from "react-icons/fa";
import { PaginatedList } from 'react-paginated-list';
import { mentors } from "../../constants/mentors";
import Header from '../../components/layouts/header/header';

export default function Team() {

    const [selectedMentor, setSelectedMentor] = useState(null);

    return (
        <div className="Team">
            <Header />
            <BgView titleClass="team-banner" img={bgTeam} title="Team" dark={true} imgTitle="" /> 
            <Col>
                <ul className='list-team'>
                    <PaginatedList
                        list={mentors}
                        itemsPerPage={10}
                        nextText='Next'
                        prevText='Prev.'
                        loopAround={false}
                        displayNumbers={true}
                        displayRange={1}
                        isLoading={false}
                        renderList={(list) => (
                            <>
                                {list.map((data) => {
                                    return (
                                        <li key={data.id} className='member-team'>
                                            <div className='member-img'>
                                                <figure className='cover-img'>
                                                    <img src={data.image} width="" height="" alt='Mentor' />
                                                </figure>
                                            </div>
                                            <div className='member-details'> 
                                                <div className='member-info'> 
                                                    <h6> {data.name} </h6>
                                                    <p> {data.profile} </p>
                                                    <div className='social d-inline-flex'>
                                                        <a href={data.linkedin} target='_blanck'><FaLinkedinIn size={15} /></a>
                                                        <a href={`mailto:${data.email}`}><FaEnvelope size={15} /></a>
                                                    </div>
                                                </div> 
                                                <div className='learn-more  d-flex justify-content-between align-items-end'>
                                                    <p className='more-infos' onClick={() => setSelectedMentor(data)}> Learn More </p>
                                                    <p> {data.location} </p>
                                                </div>
                                            </div> 
                                        </li>
                                    );
                                })}
                            </>
                        )}
                    />
                </ul>
            </Col> 
            {/* Modal */}
            <Modal   dialogClassName="cs-team-width"
                className="cs-team-modal"
                show={selectedMentor}
                onHide={() => setSelectedMentor(null)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className='team-details'>
                        <Row>
                            <Col className='picture-part  p-0'>
                                <figure>
                                    <img src={selectedMentor?.image} width="" height="" alt='Mentor' className='mb-4' />
                                </figure>
                            </Col>
                            <Col className='infos-part'>
                                <h4>{selectedMentor?.name}</h4>
                                <Markup content={selectedMentor?.intro} className='intro-member' />
                                <h6>Education</h6>
                                <Markup content={selectedMentor?.education} className='education-member' />
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}