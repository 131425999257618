import './rightPartGateway.css'; 
import { BsArrowRight } from "react-icons/bs";
import { IoMdQuote } from "react-icons/io"; 
import { Link } from 'react-router-dom'; 
import { Markup } from 'interweave';

export default function RightPartGateway(props) {

    return (
        <div className="RightPartGateway">
            {/* <p>{props.introP}</p> */}
            <Markup className='top-cs-text' content={props.introP} />
            <div className='imgs'>
                <div className='img-right'>
                    <figure className='cover-img'>
                        <img src={props.image2} width="" height="" alt="EPD right" />
                    </figure>
                </div>
                <div className='img-left'>
                    <figure  className='cover-img'>
                        <img src={props.image1} width="" height="" alt="EPD left" />
                    </figure>
                </div>
            </div>
            <div className='quote'>
                <IoMdQuote size={32} />
                <p>{props.quote}</p>
            </div>
            <Markup content={props.textP} />
            <Link to="/register">Register <BsArrowRight size={35} /></Link>
        </div>
    );
}

// props :
// introP - image1 - image2 - quote - textP