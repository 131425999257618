import './Carousel.css';
import { Link } from "react-router-dom";    
import HoverVideoPlayer from 'react-hover-video-player';
// Swiper importations
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Keyboard, Mousewheel } from 'swiper';
import React, { useState } from 'react';
import 'swiper/css';
import "swiper/css/navigation";  
import 'react-lazy-load-image-component/src/effects/blur.css'; 
// Video player importations
import "../../../../node_modules/video-react/dist/video-react.css";  
// import posterVideo from '../../../assets/images/intro_page/video-poster.png';
import { posts } from '../../../constants/posts.js';  
import Modal from 'react-bootstrap/Modal';

export default function HomeCarousel() {
    SwiperCore.use([Navigation, Keyboard, Mousewheel]); 
    const [isShown, showPointer] = useState(false);

    const [show, setShow] = useState(false);

    (function() {
        // var follower, init, mouseX, mouseY, positionElement, printout, timer;
        var follower, init, mouseX, mouseY, positionElement, printout, timer;
      
        follower = document.getElementById('mouse-pointer');
      
        printout = document.getElementById('printout');
      
        mouseX = (event) => {
          return event.clientX;
        };
      
        mouseY = (event) => {
          return event.clientY;
        };
      
        positionElement = (event) => {
          var mouse;
          mouse = {
            x: mouseX(event),
            y: mouseY(event)
          };
          follower.style.top = mouse.y + 'px';
          return follower.style.left = mouse.x + 'px';
        };
      
        timer = false;
      
        window.onmousemove = init = (event) => {
          var _event;
          _event = event;
          return timer = setTimeout(() => {
            return positionElement(_event);
          }, 1);
        };
      
      }).call(this);
    return (
        <div className="Carousel"  id="printout">
            <div id="mouse-pointer">  
                {isShown && (<div id="read-more">Learn <br></br>More</div>)} 
            </div> 

       
            <Swiper
                className='main-swiper'
                // rebuildOnUpdate={true}
                spaceBetween={0}
                slidesPerView={1}
                slidesPerGroup={1}
                loop={false}
                autoplay={false}
                direction="horizontal"
                speed={1000} 
                keyboard={true}
                mousewheel={true}
                centeredSlides={true}
                roundLengths={true}
                slideToClickedSlide={true}
                modules={[Navigation, Mousewheel, Keyboard]}
                navigation={{ 
                    prevEl: '.prev',
                    nextEl: '.next',
                    clickable: true,
                    hideOnClick: true,
                    disabledClass: 'disabled',
                }} 
            >  
                    {/* <SwiperSlide  >
                        <div className='outer-wrap'>
                        <Link to={`/gateway/0`} onClick={() => setShow(true)} onMouseEnter={() => showPointer(true)}  onMouseLeave={() => showPointer(false)}> 
                            <HoverVideoPlayer className='video-player-outer'   
                                videoSrc={video} 
                                pausedOverlay={
                                    <img
                                    src={posterVideo}
                                    alt=""
                                    style={{
                                        // Make the image expand to cover the video's dimensions
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                    />
                                }
                                loadingOverlay={
                                    <div className="loading-overlay"> 
                                    </div>
                                }
                            />  
                        </Link>
                        <div className='title-outer'>
                            <h1 className="title">Co-working Space</h1>
                        </div>
                        <h1 className="sub-title">Design Hub & Entrepreneurship Incubator</h1>
                        <p className="details">Training and developing the design responsive leaders of the future by teaching and unlocking opportunities in design, entrepreneurship & manufacturing that will improve the lives of youth in poor & vulnerable communities. Helping them to intervene in their communities, according to its contextual uniqueness. </p>
                        </div>
                    </SwiperSlide> */}

                {posts.map((data) => (
                    
                    <SwiperSlide key={data.id} >
                    <div className='outer-wrap'>
                        <Link to={`/gateway/${data.id}`}  onMouseEnter={() => showPointer(true)}  onMouseLeave={() => showPointer(false)}> 
                            <HoverVideoPlayer className='video-player-outer'   
                                videoSrc={data.videoLink} 
                                pausedOverlay={
                                    <img
                                    src={data.image}
                                    alt=""
                                    style={{
                                        // Make the image expand to cover the video's dimensions
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                    />
                                }
                                loadingOverlay={
                                    <div className="loading-overlay"> 
                                    </div>
                                }
                            />  
                        </Link>
                        <div className='title-outer'>
                            <h1 className="title">{data.title}</h1>
                        </div>
                        <h1 className="sub-title">{data.subTitle}</h1>
                        <p className="details">{data.description} 
                        </p><p className="details details-link"> 
                            <Link to={`/gateway/${data.id}`}>Learn more</Link>
                        </p>
                        </div>
                    </SwiperSlide>
                ))} 
            </Swiper>
            {/* Modal */}
             
        </div>
    )
}