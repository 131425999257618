import './intro.css';
import ButtonSimple from '../../components/form/inputs/buttonSimple/buttonSimple.js'; 
import intro_bg from '../../assets/images/intro_page/bg.png';
import intro_card_border from '../../assets/images/intro_page/border.png';

export default function Intro() {
  const IntroImage = intro_bg;
  return (
    <div className="Intro" style={{backgroundImage: `url(${IntroImage})`}}>
      {/* <div className='bg'>
        <img className='bg-intro' src={intro_bg} width="" height="" alt="bg" />
      </div> */}
      <div className="content">
        <div className="intro-card">
          <img className='img-border' src={intro_card_border} width="" height="" alt="border" />
          <div className='d-flex flex-column justify-content-between align-items-between'>
            <div className='logo'>
              <p>Root</p>
              <span>studios</span>
            </div>
            <ul>
              <li>Design</li>
              <li>Entrepreneurship</li>
              <li>Manufacturing</li>
            </ul>
          </div>
        </div>
        <p>Empowering youth in mining communities by leveraging resource revenues towards human capital development. Fostering access to local and global work opportunities </p>
        <ButtonSimple text='ENTER OUR STUDIOS' link='/home' />
      </div>
    </div>
  );
}