// Items files importations
import partner1 from '../assets/images/partners/partner-1.png';
import partner2 from '../assets/images/partners/partner-2.png';
import partner3 from '../assets/images/partners/partner-3.png';
import partner4 from '../assets/images/partners/partner-4.png';
import partner5 from '../assets/images/partners/partner-5.png';


export const partners = [
    {
        id: 1,
        image: partner1,
        title: "Carnegie Mellon University"
    },
    {
        id: 2,
        image: partner2,
        title: "Google Inc."
    },
    {
        id: 3,
        image: partner3,
        title: "Parsons School of Design"
    },
    {
        id: 4,
        image: partner4,
        title: "Microsoft Corporation"
    },
    {
        id: 5,
        image: partner5,
        title: "Resolve"
    }
];